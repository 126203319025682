import * as React from "react";
import Apple from "../../Assets/ico-apple.webp";
import Google from "../../Assets/ico-google.webp";
import Microsoft from "../../Assets/ico-microsoft.webp";
import Chase from "../../Assets/ico-chase.webp";
import Exxon from "../../Assets/ico-exxon.webp";
import GreenPeace from "../../Assets/ico-greenpeace.webp";
import Aarp from "../../Assets/ico-aarp.webp";
import Wsap from "../../Assets/ico-wsap.webp";
import Chevron from "../../Assets/ico-chevron.webp";
import Seu from "../../Assets/ico-seu.webp";
import Chamber from "../../Assets/ico-chamber.webp";
import Cpha from "../../Assets/ico-cpha.webp";
import Custom1 from "../../Assets/ico-custom1.webp";
import Cta from "../../Assets/ico-cta.webp";
import Pge from "../../Assets/ico-pge.webp";
import Afscme from "../../Assets/ico-afscme.webp";
import CapitolLadv from "../../Assets/ico-capitoladv.webp";
import Kp from "../../Assets/ico-kp.webp";
import Lang from "../../Assets/ico-lang.webp";
import California from "../../Assets/ico-california.webp";
import Axiom from "../../Assets/ico-axiom.webp";
import Major from "../../Assets/ico-major.webp";
import Nossaman from "../../Assets/ico-nossaman.webp";
import Gualco from "../../Assets/ico-gualco.webp";

const TrustedCompany = () => {
  return (
    <div id="homepage">
      <div id="trusted">
        <div className="container">
          <div className="text-center">
            <h3>
              Trusted by over <span>15,000</span> companies
            </h3>
            <p>
              Customers use Govbuddy to make dealing with California’s
              legislators easy and manageable.
            </p>
            <div className="image-list">
              <img src={Apple} alt="" />
              <img src={Google} alt="" />
              <img src={Microsoft} alt="" />
              <img src={Chase} alt="" />
              <img src={Exxon} alt="" />
              <img src={GreenPeace} alt="" />
              <img src={Aarp} alt="" />
              <img src={Wsap} alt="" />
              <img src={Chevron} alt="" />
              <img src={Seu} alt="" />
              <img src={Chamber} alt="" />
              <img src={Cpha} alt="" />
              <img src={Custom1} alt="" />
              <img src={Cta} alt="" />
              <img src={Pge} alt="" />
              <img src={Afscme} alt="" />
              <img src={CapitolLadv} alt="" />
              <img src={Kp} alt="" />
              <img src={Lang} alt="" />
              <img src={California} alt="" />
              <img src={Axiom} alt="" />
              <img src={Major} alt="" />
              <img src={Nossaman} alt="" />
              <img src={Gualco} alt="" />
            </div>
            <div className="text-center many-more">And many more</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedCompany;
