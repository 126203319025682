import Image1 from "../../Assets/sample0.webp"
import Image2 from "../../Assets/sample1.webp"
import Image3 from "../../Assets/sample2.webp"

export const Product2022 = [
  {
    id: 1,
    title: "2022 State Agency Directory Digital Edition",
    productImage: Image2,
    price: "299.95",
  },
  {
    id: 2,
    title: "2022 State Agency Directory Digital Edition",
    productImage: Image3,
    price: "299.95",
  },
  {
    id: 3,
    title: "2022 State Agency Directory Digital Edition",
    productImage: Image3,
    price: "299.95",
  },
  {
    id: 4,
    title: "2022 State Agency Directory Digital Edition",
    productImage: Image1,
    price: "299.95",
  },
];
